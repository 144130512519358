import { FormGroup, FormRow, InputBottemBorderOnly2, LangText, ModalDialog, ModalDialogType } from 'bambooo';
export class OrganizationEditModal extends ModalDialog {
    /**
     * constructor
     * @param elementObject
     */
    constructor(elementObject) {
        super(elementObject, 'organizationmodal', ModalDialogType.large);
        /**
         * ID from organization
         * @protected
         */
        this._id = null;
        const bodyCard = jQuery('<div class="card-body"/>').appendTo(this._body);
        const groupName = new FormGroup(bodyCard, 'Name');
        this._inputName = new InputBottemBorderOnly2(groupName, 'orgname');
        const groupCountry = new FormGroup(bodyCard, 'Country');
        this._inputCountry = new InputBottemBorderOnly2(groupCountry, 'country');
        const groupLocation = new FormGroup(bodyCard, 'Location');
        this._inputLocation = new InputBottemBorderOnly2(groupLocation, 'location');
        const rowPosition = new FormRow(bodyCard);
        const groupLon = new FormGroup(rowPosition.createCol(6), 'Lon');
        this._inputLon = new InputBottemBorderOnly2(groupLon, 'lon');
        const groupLat = new FormGroup(rowPosition.createCol(6), 'Lat');
        this._inputLat = new InputBottemBorderOnly2(groupLat, 'lat');
        // buttons -----------------------------------------------------------------------------------------------------
        // buttons -----------------------------------------------------------------------------------------------------
        this.addButtonClose(new LangText('Close'));
        this.addButtonSave(new LangText('Save changes'), true);
    }
    /**
     * Set id from organization
     * @param {number} id
     */
    setId(id) {
        this._id = id;
    }
    /**
     * Return id from organization
     * @returns {number|null}
     */
    getId() {
        return this._id;
    }
    /**
     * Set the organization name
     * @param {string} name
     */
    setName(name) {
        this._inputName.setValue(name);
    }
    /**
     * Return the organization name
     * @returns {string}
     */
    getName() {
        return this._inputName.getValue();
    }
    /**
     * Set the country for organization
     * @param {string} country
     */
    setCountry(country) {
        this._inputCountry.setValue(country);
    }
    /**
     * Return the country for organization
     * @returns {string}
     */
    getCountry() {
        return this._inputCountry.getValue();
    }
    /**
     * Set the location for organization
     * @param {string} location
     */
    setLocation(location) {
        this._inputLocation.setValue(location);
    }
    /**
     * Return the location from organization
     * @returns {string}
     */
    getLocation() {
        return this._inputLocation.getValue();
    }
    /**
     * Set the Lon for organization
     * @param {string} lon
     */
    setLon(lon) {
        this._inputLon.setValue(lon);
    }
    /**
     * Return the lon from organization
     */
    getLon() {
        return this._inputLon.getValue();
    }
    /**
     * Set the lat for organization
     * @param {string} lat
     */
    setLat(lat) {
        this._inputLat.setValue(lat);
    }
    /**
     * Return the lat from organization
     * @returns {string}
     */
    getLat() {
        return this._inputLat.getValue();
    }
    /**
     * resetValues
     */
    resetValues() {
        this._id = null;
        this.setName('');
        this.setCountry('');
        this.setLocation('');
        this.setLon('');
        this.setLat('');
    }
}
