import moment from 'moment';
import { LocationInput } from '../../Widget/LocationInput';
import { FormGroup, FormRow, InputBottemBorderOnly2, InputType, LangText, ModalDialog, ModalDialogType, SelectBottemBorderOnly2, Switch, Textarea } from 'bambooo';
/**
 * SightingEditModal
 */
export class SightingEditModal extends ModalDialog {
    /**
     * constructor
     * @param elementObject
     */
    constructor(elementObject) {
        super(elementObject, 'sightingmodaldialog', ModalDialogType.large);
        /**
         * id of entry
         * @protected
         */
        this._id = null;
        const bodyCard = jQuery('<div class="card-body"/>').appendTo(this._body);
        const rowFirst = new FormRow(bodyCard);
        const groupVehicle = new FormGroup(rowFirst.createCol(6), 'Boat');
        this._vehicleSelect = new SelectBottemBorderOnly2(groupVehicle);
        const groupVehicleDriver = new FormGroup(rowFirst.createCol(6), 'Skipper');
        this._vehicleDriverSelect = new SelectBottemBorderOnly2(groupVehicleDriver);
        const groupbeaufortWind = new FormGroup(bodyCard, 'Wind/Seastate (Beaufort)');
        this._beaufortWindSelect = new SelectBottemBorderOnly2(groupbeaufortWind);
        for (let i = 0; i < 13; i++) {
            this._beaufortWindSelect.addValue({
                key: `${i}`,
                value: `${i}`
            });
            this._beaufortWindSelect.addValue({
                key: `${i}.5`,
                value: `${i}.5`
            });
        }
        const groupDateSight = new FormGroup(bodyCard, 'Date');
        this._inputDateSight = new InputBottemBorderOnly2(groupDateSight, 'datesight', InputType.date);
        const rowTourTime = new FormRow(bodyCard);
        const groupTourStart = new FormGroup(rowTourTime.createCol(6), 'Start of trip');
        this._inputTourStart = new InputBottemBorderOnly2(groupTourStart, 'sighttourstart', InputType.time);
        const groupTourEnd = new FormGroup(rowTourTime.createCol(6), 'End of trip');
        this._inputTourEnd = new InputBottemBorderOnly2(groupTourEnd, 'sighttourend', InputType.time);
        const rowDurationTime = new FormRow(bodyCard);
        const groupDurationFrom = new FormGroup(rowDurationTime.createCol(6), 'Sighting duration from');
        this._inputDurationFrom = new InputBottemBorderOnly2(groupDurationFrom, 'durationfrom', InputType.time);
        const groupDurationUntil = new FormGroup(rowDurationTime.createCol(6), 'until');
        this._inputDurationUntil = new InputBottemBorderOnly2(groupDurationUntil, 'durationuntil', InputType.time);
        const groupPositionBegin = new FormGroup(bodyCard, 'Position begin');
        this._inputPositionBegin = new LocationInput(groupPositionBegin, 'positionbegin', InputType.text);
        this._inputPositionBegin.setReadOnly(true);
        const grouPositionEnd = new FormGroup(bodyCard, 'Position end');
        this._inputPositionEnd = new LocationInput(grouPositionEnd, 'positionend', InputType.text);
        this._inputPositionEnd.setReadOnly(true);
        const groupDistanceCoast = new FormGroup(bodyCard, 'Distance to nearest coast (nm)');
        this._inputDistanceCoast = new InputBottemBorderOnly2(groupDistanceCoast, 'distancecoast', InputType.number);
        this._inputDistanceCoast.setReadOnly(true);
        const rowPG = new FormRow(bodyCard);
        const groupPhotoTaken = new FormGroup(rowPG.createCol(6), 'Photos taken');
        this._switchPhotoTaken = new Switch(groupPhotoTaken, 'phototaken');
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const groupDistanceCoastEstimationGPS = new FormGroup(rowPG.createCol(6), 'Estimation without GPS');
        // this.
        const groupSpecie = new FormGroup(bodyCard, 'Species');
        this._specieSelect = new SelectBottemBorderOnly2(groupSpecie);
        const groupSpeciesCount = new FormGroup(bodyCard, 'Number of animals');
        this._inputSpeciesCount = new InputBottemBorderOnly2(groupSpeciesCount, undefined, InputType.number);
        const groupReaction = new FormGroup(bodyCard, 'Reaction');
        this._reactionSelect = new SelectBottemBorderOnly2(groupReaction);
        const groupOther = new FormGroup(bodyCard, 'Other');
        this._inputOther = new InputBottemBorderOnly2(groupOther);
        const groupOtherBoats = new FormGroup(bodyCard, 'Other boats present');
        this._otherBoats = new InputBottemBorderOnly2(groupOtherBoats);
        const groupNote = new FormGroup(bodyCard, 'Note');
        this._textareaNote = new Textarea(groupNote);
        // buttons -----------------------------------------------------------------------------------------------------
        this.addButtonClose(new LangText('Close'));
        this.addButtonSave(new LangText('Save changes'), true);
    }
    /**
     * getId
     */
    getId() {
        return this._id;
    }
    /**
     * setId
     * @param id
     */
    setId(id) {
        this._id = id;
    }
    /**
     * setVehicleList
     * @param list
     */
    setVehicleList(list) {
        this._vehicleSelect.clearValues();
        this._vehicleSelect.addValue({
            key: '0',
            value: 'Please select a Boat!'
        });
        for (const vehicle of list) {
            this._vehicleSelect.addValue({
                key: `${vehicle.id}`,
                value: vehicle.name
            });
        }
    }
    /**
     * setVehicle
     * @param vehicleId
     */
    setVehicle(vehicleId) {
        this._vehicleSelect.setSelectedValue(`${vehicleId}`);
    }
    /**
     * getVehicle
     */
    getVehicle() {
        return parseInt(this._vehicleSelect.getSelectedValue(), 10) || 0;
    }
    /**
     * setVehicleDriverList
     * @param list
     */
    setVehicleDriverList(list) {
        this._vehicleDriverSelect.clearValues();
        this._vehicleDriverSelect.addValue({
            key: '0',
            value: 'Please select a Skipper!'
        });
        for (const driver of list) {
            this._vehicleDriverSelect.addValue({
                key: `${driver.id}`,
                value: driver.user.name
            });
        }
    }
    /**
     * setVehicleDriver
     * @param driverId
     */
    setVehicleDriver(driverId) {
        this._vehicleDriverSelect.setSelectedValue(`${driverId}`);
    }
    /**
     * getVehicleDriver
     */
    getVehicleDriver() {
        return parseInt(this._vehicleDriverSelect.getSelectedValue(), 10) || 0;
    }
    /**
     * setBeaufortWind
     * @param state
     */
    setBeaufortWind(state) {
        this._beaufortWindSelect.setSelectedValue(`${state}`);
    }
    /**
     * getBeaufortWind
     */
    getBeaufortWind() {
        return this._beaufortWindSelect.getSelectedValue();
    }
    /**
     * setDateSight
     * @param date
     */
    setDateSight(date) {
        this._inputDateSight.setValue(date);
    }
    /**
     * getDateSight
     */
    getDateSight() {
        return this._inputDateSight.getValue();
    }
    /**
     * Set the tour start time.
     * @param {string} time - String time.
     */
    setTourStart(time) {
        this._inputTourStart.setValue(time);
    }
    /**
     * Return the tour start time.
     * @returns {string}
     */
    getTourStart() {
        return this._inputTourStart.getValue();
    }
    /**
     * Set the tour end time.
     * @param {string} time - String time.
     */
    setTourEnd(time) {
        this._inputTourEnd.setValue(time);
    }
    /**
     * Return the tour end time.
     * @returns {string}
     */
    getTourEnd() {
        return this._inputTourEnd.getValue();
    }
    /**
     * Set duration from time.
     * @param {string} time
     */
    setDurationFrom(time) {
        this._inputDurationFrom.setValue(time);
    }
    /**
     * Return the duration from.
     * @returns {string}
     */
    getDurationFrom() {
        return this._inputDurationFrom.getValue();
    }
    /**
     * Set the duration until time.
     * @param {string} time
     */
    setDurationUntil(time) {
        this._inputDurationUntil.setValue(time);
    }
    setPositionBegin(position) {
        this._inputPositionBegin.setValue(position);
    }
    getPositionBegin() {
        return this._inputPositionBegin.getValue();
    }
    setPositionEnd(position) {
        this._inputPositionEnd.setValue(position);
    }
    getPositionEnd() {
        return this._inputPositionEnd.getValue();
    }
    /**
     * Return the duration until.
     * @returns {string}
     */
    getDurationUntil() {
        return this._inputDurationUntil.getValue();
    }
    /**
     * Set the distance to coast input.
     * @param {string} distance
     */
    setDistanceCoast(distance) {
        this._inputDistanceCoast.setValue(distance);
    }
    /**
     * setSpeciesList
     * @param list
     */
    setSpeciesList(list) {
        this._specieSelect.clearValues();
        this._specieSelect.addValue({
            key: '0',
            value: 'Please select a specie!'
        });
        for (const specie of list) {
            if (!specie.isdeleted) {
                this._specieSelect.addValue({
                    key: `${specie.id}`,
                    value: specie.name.split(', ')[0]
                });
            }
        }
    }
    /**
     * setSpecie
     * @param specieId
     */
    setSpecie(specieId) {
        this._specieSelect.setSelectedValue(`${specieId}`);
    }
    /**
     * getSpecie
     */
    getSpecie() {
        return parseInt(this._specieSelect.getSelectedValue(), 10) || 0;
    }
    /**
     * setSpeciesCount
     * @param count
     */
    setSpeciesCount(count) {
        this._inputSpeciesCount.setValue(`${count}`);
    }
    /**
     * getSpeciesCount
     */
    getSpeciesCount() {
        return parseInt(this._inputSpeciesCount.getValue(), 10) || 0;
    }
    /**
     * setReactionList
     * @param list
     */
    setReactionList(list) {
        this._reactionSelect.clearValues();
        this._reactionSelect.addValue({
            key: '-1',
            value: 'Please select a encounter categorie!'
        });
        for (const encounter of list) {
            if (!encounter.isdeleted) {
                this._reactionSelect.addValue({
                    key: `${encounter.id}`,
                    value: encounter.name
                });
            }
        }
    }
    setReaction(reactionId) {
        this._reactionSelect.setSelectedValue(`${reactionId}`);
    }
    /**
     * getReaction
     */
    getReaction() {
        return parseInt(this._reactionSelect.getSelectedValue(), 10) || 0;
    }
    /**
     * Set other information.
     * @param {string} other
     */
    setOther(other) {
        this._inputOther.setValue(other);
    }
    /**
     * Return the other information.
     * @returns {string}
     */
    getOther() {
        return this._inputOther.getValue();
    }
    /**
     * Set other boats is present.
     * @param {string} otherBoats
     */
    setOtherBoats(otherBoats) {
        this._otherBoats.setValue(otherBoats);
    }
    /**
     * Return other boats present.
     * @returns {string}
     */
    getOtherBoats() {
        return this._otherBoats.getValue();
    }
    /**
     * Set the note.
     * @param {string} note
     */
    setNote(note) {
        this._textareaNote.setValue(note);
    }
    /**
     * Return the note.
     * @returns {string}
     */
    getNote() {
        return this._textareaNote.getValue();
    }
    /**
     * resetValues
     */
    resetValues() {
        this.setId(null);
        this.setVehicle(0);
        this.setVehicleDriver(0);
        this.setDateSight(moment(new Date()).format('YYYY.MM.DD'));
        this.setTourStart('');
        this.setTourEnd('');
        this.setDurationFrom('');
        this.setDurationUntil('');
        this.setPositionBegin('');
        this.setPositionEnd('');
        this.setDistanceCoast('');
        this.setSpecie(0);
        this.setSpeciesCount(0);
        this.setReaction(-1);
        this.setOther('');
        this.setOtherBoats('');
        this.setNote('');
    }
}
