import { Badge, BadgeType, Element, UtilColor } from 'bambooo';
/**
 * SpeciesGroupDisplay
 */
export class SpeciesGroupDisplay extends Element {
    constructor(element, group) {
        super();
        this._badge = new Badge(element, `<b style="color: ${UtilColor.getContrastYIQ(group.color)}">${group.name}</b>`, BadgeType.info, group.color);
    }
}
