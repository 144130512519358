import { FormGroup, FormRow, LangText, ModalDialog, ModalDialogType, Textarea } from 'bambooo';
/**
 * Sighting deleted modal dialog.
 */
export class SightingDeletedModal extends ModalDialog {
    /**
     * Constructor from Dialog.
     * @param {Element} elementObject
     */
    constructor(elementObject) {
        super(elementObject, 'sightingdeletemodaldialog', ModalDialogType.large);
        /**
         * ID of entry.
         * @member {number|null}
         */
        this._id = null;
        const bodyCard = jQuery('<div class="card-body"/>').appendTo(this._body);
        const rowFirst = new FormRow(bodyCard);
        const groupDesc = new FormGroup(rowFirst.createCol(6), 'Are you sure you want to delete the sighting? Please describe:');
        this._descriptionArea = new Textarea(groupDesc);
        // buttons -----------------------------------------------------------------------------------------------------
        this.addButtonClose(new LangText('Close'));
        this.addButtonSave(new LangText('Save changes'), true);
    }
    /**
     * getId
     */
    getId() {
        return this._id;
    }
    /**
     * Set the ID of entry.
     * @param {number|null} id - ID of entry.
     */
    setId(id) {
        this._id = id;
    }
    /**
     * Return the description by textarea input.
     * @returns {string}
     */
    getDescription() {
        return this._descriptionArea.getValue();
    }
    /**
     * Set the description to textarea.
     * @param {string} description
     */
    setDescription(description) {
        this._descriptionArea.setValue(description);
    }
    /**
     * resetValues
     */
    resetValues() {
        this.setId(null);
        this.setDescription('');
    }
}
