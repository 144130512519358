import { ButtonDefault, LangText, ModalDialog, ModalDialogType } from 'bambooo';
import { Map as OlMap, View } from 'ol';
import { GeoJSON } from 'ol/format';
import { Draw, Modify, Snap } from 'ol/interaction';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat } from 'ol/proj';
import { OSM } from 'ol/source';
import VectorSource from 'ol/source/Vector';
/**
 * OrganizationTrackingAreaModal
 * @see for a patch https://jsfiddle.net/ko822xjw/
 */
export class OrganizationTrackingAreaModal extends ModalDialog {
    /**
     * constructor
     * @param elementObject
     */
    constructor(elementObject) {
        super(elementObject, 'organizationtrackingareamodal', ModalDialogType.large);
        /**
         * ID from tracking area
         * @protected
         */
        this._id = null;
        /**
         * Organization id
         * @protected
         */
        this._orgId = null;
        /**
         * ol draw
         * @protected
         */
        this._draw = null;
        /**
         * ol snap
         * @protected
         */
        this._snap = null;
        /**
         * allow drawing
         * @protected
         */
        this._startDrawing = false;
        /**
         * All marked points
         * @protected
         */
        this._geoJsonStr = '';
        const bodyCard = jQuery('<div class="card-body"/>').appendTo(this._body);
        const wrapperHeight = 450;
        const mapElement = jQuery('<div></div>').appendTo(bodyCard);
        mapElement.css({
            height: `${wrapperHeight}px`
        });
        const tileLayer = new TileLayer({
            source: new OSM({
                wrapX: false
            })
        });
        this._source = new VectorSource({
            wrapX: false
        });
        const vector = new VectorLayer({
            source: this._source
        });
        this._map = new OlMap({
            layers: [tileLayer, vector],
            target: mapElement[0],
            view: new View({
                center: fromLonLat([11.030, 47.739]),
                zoom: 2.2,
                multiWorld: true
            })
        });
        this._modify = new Modify({ source: this._source });
        this._modify.on('modifyend', (event) => {
            console.log('Modify::modifyend: on event call');
            const collection = event.features;
            const writer = new GeoJSON({
                featureProjection: 'EPSG:3857',
                dataProjection: 'EPSG:4326'
            });
            this._geoJsonStr = writer.writeFeatures(collection.getArray());
            console.log(this._geoJsonStr);
        });
        this._map.addInteraction(this._modify);
        this._createDraw();
        const btnPlay = new ButtonDefault(bodyCard, 'Start Editing', 'fa-play');
        const btnStop = new ButtonDefault(bodyCard, 'Stop Editing', 'fa-stop');
        const btnClear = new ButtonDefault(bodyCard, 'Clear Points', 'fa-trash');
        btnStop.hide();
        btnPlay.setOnClickFn(() => {
            this._startDraw();
            btnPlay.hide();
            btnClear.hide();
            btnStop.show();
        });
        btnStop.setOnClickFn(() => {
            this._stopDraw();
            btnPlay.show();
            btnClear.show();
            btnStop.hide();
        });
        btnClear.setOnClickFn(() => {
            this._source.clear();
        });
        // buttons -----------------------------------------------------------------------------------------------------
        this.addButtonClose(new LangText('Close'));
        this.addButtonSave(new LangText('Save changes'), true);
    }
    _startDraw() {
        if (this._draw) {
            this._snap = new Snap({ source: this._source });
            this._map.addInteraction(this._snap);
            this._draw.setActive(true);
            this._map.addInteraction(this._draw);
            this._startDrawing = true;
        }
    }
    _stopDraw() {
        if (this._snap) {
            this._map.removeInteraction(this._snap);
            this._snap = null;
        }
        if (this._draw) {
            this._draw.setActive(false);
            this._draw.finishDrawing();
            this._map.removeInteraction(this._draw);
            this._draw = null;
        }
        this._startDrawing = false;
    }
    _createDraw() {
        this._draw = new Draw({
            source: this._source,
            type: 'Polygon',
            condition: (event) => {
                const click = event.type === 'pointerdown';
                if (this._startDrawing) {
                    return click;
                }
                return false;
            }
        });
        this._draw.on('drawend', (event) => {
            console.log('Draw::drawend: on event call');
            const writer = new GeoJSON({
                featureProjection: 'EPSG:3857',
                dataProjection: 'EPSG:4326'
            });
            this._geoJsonStr = writer.writeFeatures([event.feature]);
        });
    }
    /**
     * Set map position from Organization
     * @param lon
     * @param lat
     */
    setOrganizationPosition(lon, lat) {
        this._map.setView(new View({
            center: fromLonLat([lon, lat]),
            zoom: 16,
            multiWorld: true
        }));
    }
    /**
     * getGeoJsonStr
     * @returns {string}
     */
    getGeoJsonStr() {
        return this._geoJsonStr;
    }
    /**
     * setGeoJsonStr
     * @param {string} str
     */
    setGeoJsonStr(str) {
        const obj = new GeoJSON({
            featureProjection: 'EPSG:3857',
            dataProjection: 'EPSG:4326'
        }).readFeatures(str);
        console.log(str);
        this._source.addFeatures(obj);
    }
    /**
     * Set id from tracking area
     * @param {number} id
     */
    setId(id) {
        this._id = id;
    }
    /**
     * Return id from tracking area
     * @returns {number|null}
     */
    getId() {
        return this._id;
    }
    /**
     * Set organization id
     * @param {number} id
     */
    setOrgId(id) {
        this._orgId = id;
    }
    /**
     * Return organization id
     * @returns {number|null}
     */
    getOrgId() {
        return this._orgId;
    }
    /**
     * resetValues
     */
    resetValues() {
        this._source.clear();
        if (this._snap) {
            this._map.removeInteraction(this._snap);
            this._snap = null;
        }
        if (this._draw) {
            this._map.removeInteraction(this._draw);
            this._draw = null;
        }
        this._createDraw();
        this._id = null;
        this._orgId = null;
    }
}
