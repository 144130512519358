import { FormGroup, InputBottemBorderOnly2, LangText, ModalDialog, ModalDialogType, SelectBottemBorderOnly2 } from 'bambooo';
import { GroupRoles } from '../../Api/Group';
/**
 * Group edit modal
 */
export class GroupEditModal extends ModalDialog {
    /**
     * constructor
     * @param elementObject
     */
    constructor(elementObject) {
        super(elementObject, 'groupmodal', ModalDialogType.large);
        /**
         * ID from group
         * @protected
         */
        this._id = null;
        const bodyCard = jQuery('<div class="card-body"/>').appendTo(this._body);
        const groupName = new FormGroup(bodyCard, 'Name');
        this._inputName = new InputBottemBorderOnly2(groupName);
        const groupRole = new FormGroup(bodyCard, 'Role');
        this._selectRole = new SelectBottemBorderOnly2(groupRole);
        for (const role of Object.values(GroupRoles)) {
            this._selectRole.addValue({
                key: `${role}`,
                value: `${role}`
            });
        }
        const groupOrg = new FormGroup(bodyCard, 'Organization');
        this._selectOrganization = new SelectBottemBorderOnly2(groupOrg);
        this._selectOrganization.addValue({
            key: '0',
            value: 'None set'
        });
        // buttons -----------------------------------------------------------------------------------------------------
        this.addButtonClose(new LangText('Close'));
        this.addButtonSave(new LangText('Save changes'), true);
    }
    /**
     * Set id from group
     * @param {number} id
     */
    setId(id) {
        this._id = id;
    }
    /**
     * Return id from group
     * @returns {number|null}
     */
    getId() {
        return this._id;
    }
    /**
     * Set the group name
     * @param {string} name
     */
    setName(name) {
        this._inputName.setValue(name);
    }
    /**
     * Return the group name
     * @returns {string}
     */
    getName() {
        return this._inputName.getValue();
    }
    /**
     * resetValues
     */
    resetValues() {
        this._id = null;
        this.setName('');
        this.setRole(GroupRoles.guide);
        this._selectOrganization.clearValues();
        this.setOrganization(0);
    }
    /**
     * Set role
     * @param {string} role
     */
    setRole(role) {
        this._selectRole.setSelectedValue(role);
    }
    /**
     * Get the role
     */
    getRole() {
        return this._selectRole.getSelectedValue();
    }
    /**
     * Set the Organizations
     * @param {OrganizationFullEntry[]} orgs
     */
    setOrganizations(orgs) {
        this._selectOrganization.addValue({
            key: '0',
            value: 'None set'
        });
        for (const org of orgs) {
            this._selectOrganization.addValue({
                key: `${org.id}`,
                value: org.description
            });
        }
    }
    /**
     * Set the organization
     * @param {number} orgId
     */
    setOrganization(orgId) {
        this._selectOrganization.setSelectedValue(`${orgId}`);
    }
    /**
     * Return the organization
     * @returns {number}
     */
    getOrganization() {
        return parseInt(this._selectOrganization.getSelectedValue(), 10) || 0;
    }
}
