import { Badge, BadgeType, Element, UtilColor } from 'bambooo';
import { UtilOttLink } from '../Utils/UtilOttLink';
export class SpeciesDisplay extends Element {
    constructor(element, sighting, speciesList) {
        var _a, _b, _c;
        super();
        /**
         * turtles
         * @protected
         */
        this._turtles = [
            'Caretta caretta',
            'Dermochelys coriacea',
            'Chelonia mydas',
            'Eretmochelys imbricata',
            'Unknown sea turtle',
            'Eretmochelys imbricata - Hawksbill sea turtle',
            'Chelonia mydas - Green sea turtle',
            'Dermochelys coriacea - Leatherback sea turtle',
            'Caretta caretta - Loggerhead sea turtle'
        ];
        let specieName = '';
        let specieColor = '#ffffff';
        let ottid = null;
        const specie = speciesList.get(sighting.species_id);
        if (specie) {
            specieName = specie.name.split(',')[0];
            ottid = specie.ottid;
            if (specie.species_group) {
                specieColor = (_a = specie.species_group) === null || _a === void 0 ? void 0 : _a.color;
            }
        }
        else {
            specieName = 'not set';
            specieColor = 'red';
            if (sighting.other) {
                if (this._turtles.includes((_b = sighting.other) === null || _b === void 0 ? void 0 : _b.trim())) {
                    specieName = (_c = sighting.other) === null || _c === void 0 ? void 0 : _c.trim();
                    specieColor = '#27AE60';
                }
            }
        }
        this._badge = new Badge(element, `<b style="color: ${UtilColor.getContrastYIQ(specieColor)}">${specieName}</b>`, BadgeType.info, specieColor);
        this._element = this._badge.getElement();
        if (ottid !== null) {
            UtilOttLink.setDialog(this._element, specieName, ottid);
        }
    }
}
