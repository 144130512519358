import { FormGroup, FormRow, InputBottemBorderOnly2, InputType, LangText, ModalDialog, ModalDialogType, SelectBottemBorderOnly2, Switch } from 'bambooo';
/**
 * UsersEditModal
 */
export class UsersEditModal extends ModalDialog {
    /**
     * constructor
     * @param elementObject
     */
    constructor(elementObject) {
        super(elementObject, 'usersmodaldialog', ModalDialogType.large);
        /**
         * id of entry
         * @protected
         */
        this._id = null;
        const bodyCard = jQuery('<div class="card-body"/>').appendTo(this._body);
        const groupUsername = new FormGroup(bodyCard, 'Username');
        this._inputUsername = new InputBottemBorderOnly2(groupUsername);
        this._inputUsername.setPlaceholder('Name of user');
        const groupFullname = new FormGroup(bodyCard, 'Fullname');
        this._inputFullname = new InputBottemBorderOnly2(groupFullname);
        this._inputFullname.setPlaceholder('Fullname of user');
        const groupPassword = new FormGroup(bodyCard, 'Password');
        this._inputPassword = new InputBottemBorderOnly2(groupPassword, undefined, InputType.password);
        this._inputPassword.setPlaceholder('');
        const groupPasswordRepeat = new FormGroup(bodyCard, 'Password repeat');
        this._inputPasswordRepeat = new InputBottemBorderOnly2(groupPasswordRepeat, undefined, InputType.password);
        this._inputPasswordRepeat.setPlaceholder('');
        const groupPing = new FormGroup(bodyCard, 'Pin');
        this._inputPin = new InputBottemBorderOnly2(groupPing, undefined, InputType.password);
        this._inputPin.setPlaceholder('');
        const groupPinRepeat = new FormGroup(bodyCard, 'Pin repeat');
        this._inputPinRepeat = new InputBottemBorderOnly2(groupPinRepeat, undefined, InputType.password);
        this._inputPinRepeat.setPlaceholder('');
        const groupEMail = new FormGroup(bodyCard, 'EMail');
        this._inputEMail = new InputBottemBorderOnly2(groupEMail);
        this._inputEMail.setPlaceholder('e@mail.org');
        const groupMainGroup = new FormGroup(bodyCard, 'Main group');
        this._selectMainGroup = new SelectBottemBorderOnly2(groupMainGroup);
        const rowOpt = new FormRow(bodyCard);
        const groupIsAdmin = new FormGroup(rowOpt.createCol(4), 'Is Admin');
        this._switchIsAdmin = new Switch(groupIsAdmin, 'userisadmin');
        const groupIsDisabled = new FormGroup(rowOpt.createCol(4), 'Is Disabled');
        this._switchIsDisabled = new Switch(groupIsDisabled, 'userisdisabled');
        // buttons -----------------------------------------------------------------------------------------------------
        this.addButtonClose(new LangText('Close'));
        this.addButtonSave(new LangText('Save changes'), true);
    }
    /**
     * getId
     */
    getId() {
        return this._id;
    }
    /**
     * setId
     * @param id
     */
    setId(id) {
        this._id = id;
    }
    /**
     * getUsername
     */
    getUsername() {
        return this._inputUsername.getValue();
    }
    /**
     * setUsername
     * @param username
     */
    setUsername(username) {
        this._inputUsername.setValue(username);
    }
    /**
     * getFullname
     */
    getFullname() {
        return this._inputFullname.getValue();
    }
    /**
     * setFullname
     * @param fullname
     */
    setFullname(fullname) {
        this._inputFullname.setValue(fullname);
    }
    /**
     * getPassword
     */
    getPassword() {
        return this._inputPassword.getValue();
    }
    /**
     * setPassword
     * @param password
     */
    setPassword(password) {
        this._inputPassword.setValue(password);
    }
    /**
     * getPasswordRepeat
     */
    getPasswordRepeat() {
        return this._inputPasswordRepeat.getValue();
    }
    /**
     * setPasswordRepeat
     * @param password
     */
    setPasswordRepeat(password) {
        this._inputPasswordRepeat.setValue(password);
    }
    /**
     * getPin
     */
    getPin() {
        return this._inputPin.getValue();
    }
    setPin(pin) {
        this._inputPin.setValue(pin);
    }
    /**
     * getPinRepeat
     */
    getPinRepeat() {
        return this._inputPinRepeat.getValue();
    }
    /**
     * setPinRepeat
     * @param pin
     */
    setPinRepeat(pin) {
        this._inputPinRepeat.setValue(pin);
    }
    /**
     * getEMail
     */
    getEMail() {
        return this._inputEMail.getValue();
    }
    /**
     * setEMail
     * @param email
     */
    setEMail(email) {
        this._inputEMail.setValue(email);
    }
    /**
     * setMainGroupList
     * @param groups
     */
    setMainGroupList(groups) {
        this._selectMainGroup.clearValues();
        for (const group of groups) {
            this._selectMainGroup.addValue({
                key: `${group.id}`,
                value: group.description
            });
        }
    }
    /**
     * getMainGroup
     */
    getMainGroup() {
        return parseInt(this._selectMainGroup.getSelectedValue(), 10) || 0;
    }
    /**
     * setMainGroup
     * @param maingroup
     */
    setMainGroup(maingroup) {
        this._selectMainGroup.setSelectedValue(`${maingroup}`);
    }
    /**
     * setIsAdmin
     * @param isAdmin
     */
    setIsAdmin(isAdmin) {
        this._switchIsAdmin.setEnable(isAdmin);
    }
    /**
     * getIsAdmin
     */
    getIsAdmin() {
        return this._switchIsAdmin.isEnable();
    }
    /**
     * setIsDisabled
     * @param isDisabled
     */
    setIsDisabled(isDisabled) {
        this._switchIsDisabled.setEnable(isDisabled);
    }
    /**
     * getIsDisabled
     */
    getIsDisabled() {
        return this._switchIsDisabled.isEnable();
    }
    /**
     * resetValues
     */
    resetValues() {
        this.setId(null);
        this.setUsername('');
        this.setFullname('');
        this.setEMail('');
        this.setPassword('');
        this.setPasswordRepeat('');
        this.setPin('');
        this.setPinRepeat('');
        this.setMainGroup(0);
        this.setIsAdmin(false);
        this.setIsDisabled(false);
    }
}
