import { BasePage } from './BasePage';
/**
 * Admin
 */
export class Admin extends BasePage {
    /**
     * constructor
     */
    constructor() {
        super();
        /**
         * page name
         * @protected
         */
        this._name = 'admin';
    }
    /**
     * loadContent
     */
    async loadContent() {
        console.log('Content');
    }
}
