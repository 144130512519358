import { NetFetch } from '../Net/NetFetch';
import { UnauthorizedError } from './Error/UnauthorizedError';
import { StatusCodes } from './Status/StatusCodes';
/**
 * Speces group api
 */
export class SpeciesGroup {
    /**
     * Return a list of speces group
     */
    static async getList() {
        const result = await NetFetch.getData('/json/speciesgroup/list');
        if (result && result.statusCode) {
            switch (result.statusCode) {
                case StatusCodes.OK:
                    // eslint-disable-next-line no-case-declarations
                    const response = result;
                    return response.list;
                case StatusCodes.UNAUTHORIZED:
                    throw new UnauthorizedError();
            }
        }
        return null;
    }
}
