import { IconFa, LeftNavbarLink } from 'bambooo';
import { Lang } from '../Lang';
import { BasePage } from './BasePage';
export class Vehicle extends BasePage {
    /**
     * constructor
     */
    constructor() {
        super();
        /**
         * page name
         * @protected
         */
        this._name = 'admin-vehicle';
        // Navbar Left -------------------------------------------------------------------------------------------------
        // eslint-disable-next-line no-new
        new LeftNavbarLink(this._wrapper.getNavbar().getLeftNavbar(), 'Add Vehicle', async () => {
            return false;
        }, 'btn btn-block btn-default btn-sm', IconFa.add);
    }
    /**
     * loadContent
     */
    async loadContent() {
        this._onLoadTable = async () => {
            this._wrapper.getContentWrapper().getContent().empty();
            Lang.i().lAll();
        };
        // load table
        this._onLoadTable();
    }
}
