import { NetFetch } from '../Net/NetFetch';
import { UnauthorizedError } from './Error/UnauthorizedError';
import { StatusCodes } from './Status/StatusCodes';
/**
 * Group Roles
 */
export var GroupRoles;
(function (GroupRoles) {
    GroupRoles["admin"] = "admin";
    GroupRoles["importer"] = "importer";
    GroupRoles["driver"] = "driver";
    GroupRoles["guide"] = "guide";
})(GroupRoles || (GroupRoles = {}));
/**
 * Group
 */
export class Group {
    /**
     * getGroupList
     */
    static async getGroupList() {
        const result = await NetFetch.getData('/json/group/list');
        if (result && result.statusCode) {
            const tresult = result;
            switch (tresult.statusCode) {
                case StatusCodes.OK:
                    return tresult;
                case StatusCodes.UNAUTHORIZED:
                    throw new UnauthorizedError();
            }
        }
        return null;
    }
    /**
     * Save the group
     * @param {GroupEntry} group
     * @returns {boolean}
     */
    static async saveGroup(group) {
        const result = await NetFetch.postData('/json/group/save', group);
        if (result && result.statusCode) {
            switch (result.statusCode) {
                case StatusCodes.OK:
                    return true;
                case StatusCodes.UNAUTHORIZED:
                    throw new UnauthorizedError();
            }
        }
        return false;
    }
}
