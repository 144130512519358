import { FormGroup, InputBottemBorderOnly2, InputType, LangText, ModalDialog, ModalDialogType, SelectBottemBorderOnly2 } from 'bambooo';
/**
 * SpeciesEditModal
 */
export class SpeciesEditModal extends ModalDialog {
    /**
     * constructor
     * @param elementObject
     */
    constructor(elementObject) {
        super(elementObject, 'speciesmodaldialog', ModalDialogType.large);
        /**
         * id of entry
         * @protected
         */
        this._id = null;
        const bodyCard = jQuery('<div class="card-body"/>').appendTo(this._body);
        const groupName = new FormGroup(bodyCard, 'Specie-Name');
        this._inputName = new InputBottemBorderOnly2(groupName);
        this._inputName.setPlaceholder('Name of specie');
        const groupOttId = new FormGroup(bodyCard, 'Ott-Id');
        this._ottId = new InputBottemBorderOnly2(groupOttId, 'ottid', InputType.number);
        const groupSpeciesGroup = new FormGroup(bodyCard, 'Specie-Group');
        this._selectSpeciesGroup = new SelectBottemBorderOnly2(groupSpeciesGroup);
        // buttons -----------------------------------------------------------------------------------------------------
        this.addButtonClose(new LangText('Close'));
        this.addButtonSave(new LangText('Save changes'), true);
    }
    /**
     * getId
     */
    getId() {
        return this._id;
    }
    /**
     * setId
     * @param id
     */
    setId(id) {
        this._id = id;
    }
    /**
     * getName
     */
    getName() {
        return this._inputName.getValue();
    }
    /**
     * setName
     * @param name
     */
    setName(name) {
        this._inputName.setValue(name);
    }
    /**
     * Set the Ott ID
     * @param {number} id
     */
    setOttId(id) {
        this._ottId.setValue(`${id}`);
    }
    /**
     * Return the Ott ID
     * @returns {number}
     */
    getOttId() {
        return parseInt(this._ottId.getValue(), 10) || 0;
    }
    /**
     * Set species group list
     * @param {SpeciesGroupEntry[]} groups
     */
    setSpeciesGroupList(groups) {
        this._selectSpeciesGroup.clearValues();
        for (const group of groups) {
            this._selectSpeciesGroup.addValue({
                key: `${group.id}`,
                value: group.name
            });
        }
    }
    /**
     * Set the species group
     * @param {number} speciesGroupId
     */
    setSpeciesGroup(speciesGroupId) {
        this._selectSpeciesGroup.setSelectedValue(`${speciesGroupId}`);
    }
    /**
     * Return the species group
     * @returns {number}
     */
    getSpeciesGroup() {
        return parseInt(this._selectSpeciesGroup.getSelectedValue(), 10) || 0;
    }
    /**
     * resetValues
     */
    resetValues() {
        this.setId(null);
        this.setName('');
        this.setSpeciesGroup(0);
    }
}
