/**
 * UtilLocation
 */
export class UtilLocation {
    /**
     * ddToDm
     * @param {number} coord
     * @param {boolean} isLat
     */
    static ddToDm(coord, isLat) {
        let direction = '';
        if (isLat) {
            direction = coord >= 0 ? 'N' : 'S';
        }
        else {
            direction = coord >= 0 ? 'E' : 'W';
        }
        const coordInt = Math.trunc(coord);
        const degree = Math.abs(coordInt);
        const minute = (coord - coordInt) * 0.6;
        return {
            direction,
            degree,
            minute: Math.abs(minute)
        };
    }
    /**
     * Convert a string (json) to a Geoloaction coordinates
     * @param {string} str
     * @returns {GeolocationCoordinates|null}
     */
    static strToGeolocationCoordinates(str) {
        let gcValue = null;
        try {
            gcValue = JSON.parse(str);
            return gcValue;
        }
        catch (e) {
            console.log(e);
            return null;
        }
    }
    /**
     * Convert a Geolocation to OL Coordinates
     * @param {GeolocationCoordinates} geo
     * @returns {Coordinate}
     */
    static geoLocationToOlCoordinates(geo) {
        if (geo.longitude && geo.latitude) {
            return [geo.longitude, geo.latitude];
        }
        return [0, 0];
    }
}
